
import imageDemo from "assets/images/spa_time/spa.jpg";
import tx_estetico from "assets/images/spa_images/photo_4.jpg";
import tx_podologico from "assets/images/spa_images/photo_22.jpg";
import aroma from "assets/images/spa_time/aroma.jpg";
import tx_cosme from "assets/images/spa_images/photo_5.jpg";

const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/pages";

export default [
  {
    image: tx_estetico,
    name: "Tratamientos estéticos",
  },
  {
    image: tx_podologico,
    name: "Podológicos",
  },
  {
    image: tx_cosme,
    name: "Cosmetologícos",
  }
];
